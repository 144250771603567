<template>
    <breadCrumb/>
    <subsidiaries/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import subsidiaries  from '../components/presence/subsidiaries.vue'
export default {
    components: {
        breadCrumb, 
        subsidiaries
    },
    data(){
        return{}
    }
}
</script>