<template>
    <section id="ft-featured" class="ft-featured-section">
		<div class="container">
			<div class="ft-section-title-2 headline pera-content text-center">
				<span class="sub-title">our subsidiaries</span>
				<h2>Our
					<span>Subsidiaries</span>
				</h2>
			</div>
			<div class="ft-featured-content">
				<div class="row justify-content-center">  
					<div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">Winwin Lines Pte Ltd</a></h3> 
                                <div class="addressWrap">
                                    <div class="title">Address :</div>
                                    <div class="address">7500A BEACH ROAD #14-302 THE PLAZA SINGAPORE - 199591</div>
                                </div>
							</div>
						</div>
					</div>
                    <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">Winwin Agency Holdings Pte Ltd</a></h3> 
                                <div class="addressWrap">
                                    <div class="title">Address :</div>
                                    <div class="address">7500A BEACH ROAD #14-302 THE PLAZA SINGAPORE - 199591</div>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    data(){
        return{}
    }
}
</script>